@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

$font: "Noto Sans KR", sans-serif;
$logoColor: #36579e;

@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin desktop {
  @media (max-width: 1400px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 1150px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 700px) {
    @content;
  }
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
ul,
ol,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
:root {
  --vh: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font;
}
body.fixed {
  height: 100%;
  overflow-y: hidden;
}

// NAV
.App {
  position: relative;
  top: 0;
  left: 0;
  overflow-x: hidden;
  .nav.fixed {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    transition: 1s;
  }
  .nav {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    transition: 1s;

    .nav-inner {
      width: 100%;
      max-width: 1270px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      @include desktop() {
        max-width: 1024px;
      }
      @include tablet() {
        max-width: auto;
        width: 90%;
      }
      @include mobile() {
        padding: 15px 0;
      }
      .logo-box {
        width: 300px;
        cursor: pointer;
        @include desktop() {
          width: 250px;
        }
        @include tablet() {
          width: 220px;
        }
        @include mobile {
          width: 170px;
        }
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .gnb {
        @include tablet() {
          display: none;
        }
        ul {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
          padding: 0;
          li.active {
            span {
              text-decoration: underline;
            }
          }
          li {
            cursor: pointer;
            span {
              font-size: 17px;
              font-weight: 500;
              display: block;
              width: 100%;
              height: 100%;
              padding: 20px 20px;
              color: white;
              @include desktop() {
                font-size: 15px;
                padding: 14px 18px;
              }
            }
            .dropdown {
              width: 100%;
              height: 100%;
              button {
                width: 100%;
                height: 100%;
                padding: 20px 20px;
                --bs-btn-bg: none;
                --bs-btn-color: white;
                --bs-btn-border-color: none;
                --bs-btn-hover-bg: none;
                --bs-btn-hover-border-color: none;
                --bs-btn-focus-shadow-rgb: none;
                --bs-btn-active-bg: none;
                --bs-btn-disabled-bg: none;
                border-radius: 0;
                font-size: 17px;
                font-weight: 500;
                @include desktop() {
                  font-size: 15px;
                  padding: 14px 18px;
                }
                &:hover {
                  color: white;
                  background-color: transparent;
                }
              }
              .btn.show {
                color: white;
                background-color: $logoColor;
                border-color: none;
              }
              .dropdown-menu {
                border-radius: 0;
                a {
                  font-size: 16px;
                  &:hover {
                    background-color: $logoColor;
                    color: white;
                  }
                  @include desktop() {
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
      }
      // 햄버거 메뉴
      .hambeger-menu {
        display: none;
        @include tablet() {
          display: block;
          .hamberger-bar {
            width: 40px;
            height: 40px;
            position: relative;
            @include flexCenter();
            flex-direction: column;
            gap: 5px;
            cursor: pointer;
            @include mobile() {
              width: 37px;
              height: 37px;
            }
            span {
              display: block;
              width: 70%;
              height: 2px;
              background-color: white;
            }
          }
        }
      }

      .hamberger-gnb {
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;

        .hamberger-inner {
          width: 400px;
          height: 100%;
          background-color: white;
          position: absolute;
          top: 0;
          right: 0;
          @include mobile() {
            width: 80%;
            top: 0;
            right: auto;
            left: 0;
          }
          .close-btn {
            width: 100%;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @include mobile() {
              position: absolute;
              top: 0;
              right: -20%;
            }
            span {
              font-size: 20px;
              font-weight: 700;
              cursor: pointer;
              @include mobile() {
                font-size: 23px;
                color: white;
              }
            }
          }
          .hamberger-menu-box {
            width: 100%;
            height: calc(100% - 70px);
            @include mobile() {
              height: 100%;
            }
            ul {
              width: 100%;
              height: 100%;
              margin: 0;
              padding: 0;
              @include flexCenter();
              flex-direction: column;

              li {
                width: 100%;
                padding: 20px 0;
                @include flexCenter();
                cursor: pointer;

                span {
                  font-size: 20px;
                  font-weight: 500;
                  color: #333;
                }
              }
              .hamberger-lan {
                border: none;
                margin-top: 50px;
                display: flex;
                align-items: center;
                gap: 30px;
                cursor: default;
                span {
                  font-size: 16px;
                  color: #999;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Home
.home {
  width: 100%;
  .home-banner {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background-color: beige;
    position: relative;
    @include flexCenter();
    .swiper {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .swiper-slide {
        position: relative;

        .swiper-bg {
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.4);
          position: absolute;
          top: 0;
          left: 0;
        }
        .home-banner-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          max-width: 1270px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          pointer-events: none;
          z-index: 9;
          transition: 1s;
          opacity: 0;
          @include desktop() {
            max-width: 1024px;
          }
          @include tablet() {
            max-width: auto;
            width: 90%;
            top: 80%;
          }
          @include mobile() {
            top: auto;
            bottom: 50px;
            transform: none;
            transform: translateX(-50%);
          }
          strong {
            font-size: 47px;
            color: white;
            @include desktop() {
              font-size: 43px;
            }
            @include tablet() {
              font-size: 38px;
            }
            @include mobile() {
              font-size: 27px;
            }
          }
          span {
            font-size: 20px;
            font-weight: 600;
            color: white;
            @include desktop() {
              font-size: 19px;
            }
            @include tablet() {
              font-size: 18px;
              font-weight: 500;
            }
            @include mobile() {
              font-size: 14px;
            }
          }
        }
      }
      .swiper-slide-active {
        transition: 0.5s;
        .home-banner-text {
          transition: 1s;
          opacity: 1;
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        color: white;
        transform: scale(0.7);
        @include desktop() {
          transform: scale(0.5);
        }
        @include mobile() {
          transform: scale(0.4);
        }
      }
    }
  }
  .inner {
    width: 100%;
    max-width: 1270px;
    margin: 0 auto;
    @include desktop() {
      max-width: 1024px;
    }
    @include tablet() {
      max-width: auto;
      width: 90%;
    }
    .home-about {
      width: 100%;
      margin-top: 50px;
      border-top: 5px solid black;
      padding-top: 30px;
      @include desktop() {
        border-top: 4px solid black;
      }
      @include tablet() {
        border-top: 3px solid black;
      }
      h3 {
        width: 100%;
        font-size: 32px;
        position: relative;
        display: flex;
        align-items: center;
        color: #001c51;
        pointer-events: none;
        @include desktop() {
          font-size: 30px;
        }
        @include tablet() {
          font-size: 27px;
        }
        @include mobile() {
          font-size: 21px;
          font-weight: 600;
        }
      }
      .home-about-content-box {
        width: 100%;
        margin-top: 80px;
        display: flex;
        justify-content: flex-end;
        gap: 50px;
        @include desktop() {
          margin-top: 50px;
          justify-content: flex-start;
        }
        @include tablet() {
          justify-content: space-between;
        }
        @include mobile() {
          flex-direction: column;
          gap: 0;
        }
        .home-about-content {
          width: 400px;
          @include tablet() {
            width: 45%;
          }
          @include mobile() {
            width: 100%;
          }
          p {
            font-size: 20px;
            line-height: 33px;
            pointer-events: none;
            @include desktop() {
              font-size: 18px;
            }
            @include tablet() {
              font-size: 16px;
            }
            @include mobile() {
              font-size: 14px;
            }
          }
        }
      }
    }
    .home-link {
      width: 100%;
      margin-top: 60px;
      height: 520px;
      display: grid;
      grid-template-columns: repeat(2, 620px);
      grid-template-rows: repeat(2, 240px);
      grid-gap: 20px;
      justify-content: space-between;
      align-content: space-between;
      @include desktop() {
        grid-template-columns: repeat(2, 490px);
        grid-template-rows: repeat(2, 240px);
      }
      @include tablet() {
        height: 450px;
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: repeat(2, 220px);
      }
      @include mobile() {
        display: flex;
        flex-direction: column;
        height: auto;
      }
      .home-introduction {
        grid-row: 1/3;
        @include mobile() {
          height: 300px;
        }
        p {
          width: 100%;
          font-size: 35px;
          span {
            position: relative;
            top: 0;
            left: 0;
            font-size: 18px;
            .br {
              display: none;
            }
          }
          @include desktop() {
            font-size: 27px;
            span {
              font-size: 17px;
              font-weight: 400;
              .br {
                display: block;
              }
            }
          }
          @include mobile() {
            font-size: 25px;
            width: 100%;
            text-align: center;
            span {
              top: 20px;
              left: 50%;
              .br {
                display: none;
              }
            }
          }
        }
      }
      div {
        position: relative;
        cursor: pointer;
        @include mobile() {
          height: 150px;
        }
        .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.1);
        }
        span,
        p {
          display: block;
          position: absolute;
          font-size: 23px;
          font-weight: 500;
          top: 20px;
          left: 20px;
          color: white;
          transition: 0.3s;
          @include desktop() {
            font-size: 20px;
          }
          @include mobile() {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 21px;
          }
        }
      }
      .home-introduction {
        background: url(../public/assets/image/home-introduction.jpg) center
          center no-repeat;
        background-size: cover;
      }
      .home-quotes {
        background: url(../public/assets/image/home-quotes.jpg) center center
          no-repeat;
        background-size: cover;
      }
      .home-directions {
        background: url(../public/assets/image/home-directions.jpg) center
          center no-repeat;
        background-size: cover;
      }
    }
    .home-partners {
      width: 100%;
      margin-top: 90px;
      p {
        font-size: 32px;
        font-weight: 600;
        @include desktop() {
          font-size: 30px;
          font-weight: 500;
        }
        @include tablet() {
          font-size: 27px;
        }
        @include mobile() {
          font-size: 21px;
          font-weight: 600;
        }
      }
      ul {
        width: 100%;
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0;
        li {
          width: 22%;
          aspect-ratio: 2/1;
          margin-bottom: 30px;
          overflow: hidden;
          @include flexCenter();
          &:nth-child(12),
          &:nth-child(11) {
            img {
              width: auto;
              height: 100%;
              object-fit: cover;
            }
          }
          img {
            width: 100%;
            object-fit: cover;
          }
          @include tablet() {
            width: 30%;
          }
          @include mobile() {
            width: 45%;
          }
        }
      }
    }
  }
}

// ABOUT
.about {
  width: 100%;
  .about-banner {
    width: 100%;
    height: 420px;
    background: url("../public/assets/image/home-introduction.jpg") center
      center no-repeat;
    background-size: cover;
    position: relative;
    @include desktop() {
      height: 270px;
    }
    @include tablet() {
      height: 200px;
    }
    @include mobile() {
      height: 170px;
    }
    .about-banner-inner-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
    }
    .about-banner-inner {
      width: 100%;
      height: 100%;
      max-width: 1270px;
      margin: 0 auto;
      position: relative;
      @include desktop() {
        max-width: 1024px;
      }
      @include tablet() {
        max-width: auto;
        width: 90%;
      }
      strong {
        font-size: 47px;
        position: absolute;
        bottom: 130px;
        pointer-events: none;
        color: white;
        @include desktop() {
          font-size: 35px;
          bottom: 50px;
        }
        @include tablet() {
          font-size: 28px;
          bottom: 20px;
        }
        @include mobile() {
          font-size: 21px;
        }
      }
    }
  }
  .about-inner {
    width: 100%;
    max-width: 1270px;
    margin: 0 auto;
    @include desktop() {
      max-width: 1024px;
    }
    @include tablet() {
      max-width: auto;
      width: 90%;
    }
    .about-inner-title {
      width: 100%;
      margin-top: 50px;
      border-top: 5px solid black;
      padding-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 0px;
      @include desktop() {
        border-top: 4px solid black;
      }
      @include tablet() {
        border-top: 3px solid black;
      }
      p {
        font-size: 20px;
        font-weight: 400;
        pointer-events: none;
        color: black;
        line-height: 37px;
        @include desktop() {
          font-size: 19px;
        }
        @include tablet() {
          font-size: 18px;
        }
        @include mobile() {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
    .about-introduction-box {
      width: 100%;
      height: auto;
      margin-top: 90px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @include tablet() {
        height: auto;
        align-items: flex-start;
      }
      @include mobile() {
        flex-direction: column;
        gap: 50px;
      }

      .introduction {
        width: 48%;
        height: 100%;
        @include mobile() {
          width: 100%;
        }
        .introduction-image {
          width: 100%;
          height: 400px;
          @include desktop() {
            height: 350px;
          }
          @include tablet() {
            height: 300px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .introduction-content-wrapper {
          width: 100%;
          height: 50%;
          @include tablet() {
            height: auto;
          }

          strong {
            display: block;
            font-size: 32px;
            color: $logoColor;
            pointer-events: none;
            padding: 15px 0px;
            @include desktop() {
              font-size: 30px;
            }
            @include tablet() {
              font-size: 25px;
            }
            @include mobile() {
              font-size: 21px;
            }
          }
          .introduction-content {
            display: flex;
            flex-direction: column;
            gap: 15px;
            pointer-events: none;

            p {
              margin: 0;
              padding: 0;
              font-size: 16px;
              color: black;
              font-weight: 400;
              padding-right: 20px;
              @include tablet() {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .about-customer {
    width: 100%;
    margin-top: 90px;
    .about-customer-box.first {
      background-color: #d4f1fe;
    }
    .about-customer-box.second {
      background-color: #001c51;
      .customer-inner {
        flex-direction: row-reverse;
        @include tablet() {
          flex-direction: column-reverse;
        }
        .customer-content {
          strong {
            color: white;
          }
          .about-content {
            p {
              color: white;
            }
          }
        }
      }
    }

    .about-customer-box {
      width: 100%;
      padding: 50px 0;
      .customer-inner {
        width: 100%;
        max-width: 1270px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        @include desktop() {
          max-width: 1024px;
        }
        @include tablet() {
          max-width: auto;
          width: 90%;
          flex-direction: column-reverse;
        }

        .customer-content {
          width: 45%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          @include tablet() {
            width: 100%;
          }
          strong {
            display: block;
            font-size: 32px;
            color: #001c51;
            pointer-events: none;
            padding: 0px 0px 15px;
            @include desktop() {
              font-size: 30px;
            }
            @include tablet() {
              font-size: 25px;
              padding: 15px 0;
            }
            @include mobile() {
              font-size: 21px;
            }
          }
          .about-content {
            display: flex;
            flex-direction: column;
            gap: 15px;
            pointer-events: none;

            p {
              margin: 0;
              padding: 0;
              font-size: 16px;
              color: black;
              font-weight: 400;
              padding-right: 20px;
              @include tablet() {
                font-size: 14px;
                padding: 0;
              }
            }
          }
        }
        .customer-image {
          width: 45%;
          background-color: beige;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          @include tablet() {
            width: 100%;
          }
        }
      }
    }
  }
}

// Service
.service {
  width: 100%;
  .service-banner {
    width: 100%;
    height: 420px;
    background: url("../public/assets/image/service/banner.jpg") center center
      no-repeat;
    background-size: cover;
    position: relative;
    @include desktop() {
      height: 270px;
    }
    @include tablet() {
      height: 200px;
    }
    @include mobile() {
      height: 170px;
    }
    .service-banner-inner-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
    }
    .service-banner-inner {
      width: 100%;
      height: 100%;
      max-width: 1270px;
      margin: 0 auto;
      position: relative;
      @include desktop() {
        max-width: 1024px;
      }
      @include tablet() {
        max-width: auto;
        width: 90%;
      }
      strong {
        font-size: 47px;
        position: absolute;
        bottom: 130px;
        pointer-events: none;
        color: white;
        @include desktop() {
          font-size: 35px;
          bottom: 50px;
        }
        @include tablet() {
          font-size: 28px;
          bottom: 20px;
        }
        @include mobile() {
          font-size: 21px;
        }
      }
    }
  }
  .service-inner {
    width: 100%;
    max-width: 1270px;
    margin: 0 auto;
    padding: 50px 0;
    @include desktop() {
      max-width: 1024px;
    }
    @include tablet() {
      max-width: auto;
      width: 90%;
    }
    h3 {
      width: 100%;
      font-size: 32px;
      position: relative;
      display: flex;
      align-items: center;
      color: #001c51;
      pointer-events: none;
      @include desktop() {
        font-size: 30px;
      }
      @include tablet() {
        font-size: 27px;
      }
      @include mobile() {
        font-size: 21px;
        font-weight: 600;
      }
    }
    p {
      font-size: 20px;
      line-height: 33px;
      color: black;
      padding: 20px 0;
      pointer-events: none;
      @include desktop() {
        font-size: 19px;
      }
      @include tablet() {
        font-size: 18px;
      }
      @include mobile() {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
  .service-customer {
    width: 100%;
    margin-top: 50px;
    @include mobile() {
      margin-top: 0;
    }

    .service-customer-box.second,
    .service-customer-box.four {
      .customer-inner {
        flex-direction: row-reverse;
        @include tablet() {
          flex-direction: column-reverse;
        }
      }
    }

    .service-customer-box {
      width: 100%;
      padding: 50px 0;
      .customer-inner {
        width: 100%;
        max-width: 1270px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        @include desktop() {
          max-width: 1024px;
        }
        @include tablet() {
          max-width: auto;
          width: 90%;
          flex-direction: column-reverse;
        }

        .customer-content {
          width: 45%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          @include tablet() {
            width: 100%;
          }
          strong {
            display: block;
            font-size: 32px;
            color: #001c51;
            pointer-events: none;
            padding: 0px 0px 15px;
            @include desktop() {
              font-size: 30px;
            }
            @include tablet() {
              font-size: 25px;
              padding: 15px 0;
            }
            @include mobile() {
              font-size: 21px;
            }
          }
          .service-content {
            display: flex;
            flex-direction: column;
            gap: 15px;
            pointer-events: none;

            p {
              margin: 0;
              padding: 0;
              font-size: 16px;
              color: black;
              font-weight: 400;
              padding-right: 20px;
              @include tablet() {
                font-size: 14px;
                padding: 0;
              }
            }
          }
        }
        .customer-image {
          width: 45%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          @include tablet() {
            width: 100%;
          }
        }
      }
    }
  }
}

// Inqury
.inqury {
  width: 100%;
  .inqury-banner {
    width: 100%;
    height: 420px;
    background: url("../public/assets/image/quotes/banner.jpg") center center
      no-repeat;
    background-size: cover;
    position: relative;
    @include desktop() {
      height: 270px;
    }
    @include tablet() {
      height: 200px;
    }
    @include mobile() {
      height: 170px;
    }
    .inqury-banner-inner-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
    }
    .inqury-banner-inner {
      width: 100%;
      height: 100%;
      max-width: 1270px;
      margin: 0 auto;
      position: relative;
      @include desktop() {
        max-width: 1024px;
      }
      @include tablet() {
        max-width: auto;
        width: 90%;
      }
      strong {
        font-size: 47px;
        position: absolute;
        bottom: 130px;
        pointer-events: none;
        color: white;
        @include desktop() {
          font-size: 35px;
          bottom: 50px;
        }
        @include tablet() {
          font-size: 28px;
          bottom: 20px;
        }
        @include mobile() {
          font-size: 21px;
        }
      }
    }
  }
  .inqury-inner {
    width: 100%;
    max-width: 1270px;
    margin: 0 auto;
    padding: 50px 0;
    @include desktop() {
      max-width: 1024px;
    }
    @include tablet() {
      max-width: auto;
      width: 90%;
    }
    strong {
      font-size: 32px;
      pointer-events: none;
      color: #001c51;
      @include desktop() {
        font-size: 30px;
      }
      @include tablet() {
        font-size: 27px;
      }
      @include mobile() {
        font-size: 21px;
      }
    }
    form {
      width: 100%;
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include tablet() {
        flex-direction: column;
        justify-content: flex-start;
      }

      label {
        font-size: 18px;
        font-weight: 500;
        color: #999;
        @include desktop() {
          font-size: 16px;
        }
        @include mobile() {
          font-size: 14px;
        }
        span {
          color: red;
        }
      }
      input {
        width: 100%;
        border: none;
        outline: none;
        font-family: $font;
        font-weight: 600;
        font-size: 14px;
        border-bottom: 1px solid black;
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox  */
      input[type="number"] {
        -moz-appearance: textfield;
      }

      .name,
      .phone,
      .email,
      .hs-code {
        width: 45%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 50px;
        input {
          padding: 10px 2px;
        }
        @include tablet() {
          width: 100%;
        }
      }
      .delivery,
      .container,
      .subject {
        width: 45%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 0;
        margin-bottom: 50px;
        padding: 0;
        @include tablet() {
          gap: 15px;
          width: 100%;
        }
        .delivery-box,
        .container-box {
          width: 100%;
          display: flex;
          gap: 30px;
          div {
            display: flex;
            align-items: center;
            gap: 2px;
            cursor: pointer;
            input {
              width: 15px;
              height: 15px;
              @include desktop() {
                width: 14px;
                height: 14px;
              }
            }
            span {
              font-size: 16px;
              font-weight: 600;
              @include desktop() {
                font-size: 15px;
              }
              @include mobile() {
                font-size: 14px;
              }
            }
          }
        }
      }
      .message {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include tablet() {
          grid-area: 15px;
        }
        textarea {
          width: 100%;
          height: 180px;
          border: 1px solid black;
          outline: none;
          resize: none;
          font-family: $font;
          font-weight: 600;
          font-size: 14px;
          padding: 10px 2px;
          @include mobile() {
            height: 150px;
          }
        }
      }
      .agree {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 30px;
        gap: 5px;
        input {
          width: 15px;
          height: 15px;
          @include desktop() {
            width: 14px;
            height: 14px;
          }
        }
        p {
          font-size: 16px;
          font-weight: 700;
          cursor: pointer;
          margin: 0;
          padding: 0;
          &:hover + span {
            color: #36579e;
            text-decoration: underline;
          }
          @include desktop() {
            font-size: 15px;
          }
          @include mobile() {
            font-size: 14px;
          }
        }
        span {
          font-size: 16px;
          font-weight: 700;
          cursor: pointer;
          margin: 0;
          padding: 0;
          &:hover {
            color: #36579e;
            text-decoration: underline;
          }
          @include desktop() {
            font-size: 15px;
          }
          @include mobile() {
            font-size: 14px;
          }
        }
        .policy {
          width: 100%;
          height: calc(var(--vh, 1vh) * 100);
          position: fixed;
          top: 0;
          left: 0;
          background-color: white;
          display: flex;
          flex-direction: column;
          gap: 30px;
          padding: 100px 30px 30px;
          z-index: 100;
          overflow-y: scroll;
          @include tablet() {
            padding: 100px 30px 30px;
          }
          p {
            font-size: 14px;
            padding: 0;
            margin: 0;
          }
          span {
            font-size: 14px;
          }
          .close {
            font-size: 20px;
            position: absolute;
            top: 20px;
            right: 50px;
            cursor: pointer;
            @include mobile() {
              right: 20px;
              font-size: 25px;
            }
          }
        }
      }
      button {
        margin: 50px auto 0;
        font-size: 18px;
        font-weight: 500;
        outline: none;
        border: none;
        background-color: #999;
        color: white;
        padding: 15px 80px;
        @include desktop() {
          font-size: 16px;
          padding: 13px 60px;
        }
      }
    }
  }
}

// MAP
.map {
  width: 100%;
  .map-banner {
    width: 100%;
    height: 420px;
    background: url("../public/assets/image/map/banner.jpg") center center
      no-repeat;
    background-size: cover;
    position: relative;
    @include desktop() {
      height: 270px;
    }
    @include tablet() {
      height: 200px;
    }
    @include mobile() {
      height: 170px;
    }
    .map-banner-inner-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
    }
    .map-banner-inner {
      width: 100%;
      height: 100%;
      max-width: 1270px;
      margin: 0 auto;
      position: relative;
      @include desktop() {
        max-width: 1024px;
      }
      @include tablet() {
        max-width: auto;
        width: 90%;
      }
      strong {
        font-size: 47px;
        position: absolute;
        bottom: 130px;
        pointer-events: none;
        color: white;
        @include desktop() {
          font-size: 35px;
          bottom: 50px;
          display: flex;
          flex-direction: column;
          .mobile {
            display: none;
          }
        }
        @include tablet() {
          font-size: 28px;
          bottom: 20px;
        }
        @include mobile() {
          font-size: 21px;
        }
        span {
          font-size: 20px;
          @include desktop() {
            font-size: 18px;
          }
          @include tablet() {
            font-size: 16px;
            font-weight: 500;
          }
          @include mobile() {
            font-size: 14px;
          }
        }
      }
    }
  }
  .map-wrapper {
    width: 100%;
    max-width: 1270px;
    height: 500px;
    margin: 90px auto 0;
    overflow: hidden;
    background-color: beige;
    @include desktop() {
      max-width: 1024px;
    }
    @include tablet() {
      max-width: auto;
      width: 90%;
      height: 400px;
    }
    @include mobile() {
      margin-top: 50px;
    }
  }
  .map-content {
    width: 100%;
    max-width: 1270px;
    margin: 50px auto 0;
    @include flexCenter();
    justify-content: space-between;
    align-items: flex-start;
    @include desktop() {
      max-width: 1024px;
    }
    @include tablet() {
      max-width: auto;
      width: 90%;
      flex-direction: column;
      gap: 30px;
    }
    div {
      display: flex;
      flex-direction: column;
      p {
        font-size: 23px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        pointer-events: none;
        @include desktop() {
          font-size: 20px;
        }
        @include tablet() {
          font-size: 18px;
        }
      }
      span {
        display: block;
        margin: 5px 0;
        font-size: 21px;
        color: black;
        pointer-events: none;
        @include desktop() {
          font-size: 16px;
        }
        @include tablet() {
          font-size: 14px;
        }
      }
    }
  }
}

// Footer
.footer {
  width: 100%;
  background-color: #333;
  margin-top: 90px;
  .footer-inner {
    padding: 30px 0;
    width: 100%;
    max-width: 1270px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    pointer-events: none;
    @include desktop() {
      max-width: 1024px;
    }
    @include tablet() {
      max-width: auto;
      width: 90%;
    }
    @include mobile() {
      gap: 15px;
      padding: 40px 0;
    }
    h1 {
      font-size: 18px;
      color: white;
    }
    .footer-section-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2px;
      @include mobile() {
        gap: 7px;
      }
      span {
        display: block;
        font-size: 12px;
        color: white;
        font-weight: 300;
      }
      .copy {
        font-size: 10px;
      }
    }
  }
}
